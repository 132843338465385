.doctor_profile_wrap{
    .appoin_card{
        display: flex;
        justify-content: center;
        flex-direction: column;
        .content{
            text-align: center;
            font-size: 44px;
            font-weight: bold;
            color:rgba(0,0,0,0.75);
        }
        .desc{
            text-align: center;
            font-size: 14px;
            color:rgba(0,0,0,0.5);
        }
    }
}