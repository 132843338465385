.appointment_home_main {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 22px;
        grid-row-gap: 22px;

        @media screen and (max-width:800px) {
            grid-template-columns: 1fr;
        }

        .top_left {
            p {
                margin: 0;
                font-weight: bold;
                color: #314c7c;
                padding-bottom: 16px;
                @media screen and (max-width:768px) {
                    font-size: 14px;
                }
            }
        }
    }

    .top_clinic_wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        border-radius: 6px;

        .clinic_top_wrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;

            p {
                margin: 0;
                font-size: 20px;
                font-weight: 500;
            }

            img {
                height: 26px;
                width: 26px;
            }
        }

        .top_clinic_grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 22px;
            grid-row-gap: 22px;

            @media screen and (max-width:1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width:556px) {
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
            }

            .profile_card_as_link {
                background-color: white;
                cursor: pointer;
                border-radius: 12px;
                padding: 12px;

                &:hover {
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                }

                .card {
                    position: relative;
                    transition: all .25s;
                    width: 100%;

                    .menu {
                        position: absolute;
                        right: 6px;
                        top: 12px;
                        display: none;

                        img {
                            height: 28px;
                            width: 28px;
                            cursor: pointer;
                        }
                    }

                    .profile_pic {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 16px 0;

                        img {
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 3px 3px 10px #d9d9d9,
                                -3px -3px 10px #ffffff;
                            height: 84px;
                            width: 84px;
                            padding: 4px;
                        }
                    }

                    .gridview_card_content {
                        .content_item {
                            margin-bottom: 16px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .patient_details {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                p {
                                    margin: 0;
                                }
                            }

                            .title {
                                color: #314c7c;
                                text-align: center;
                                font-size: 26px;
                                font-weight: bold;
                                padding-bottom: 6px;
                            }

                            .clinic_name {
                                color: #00842c;
                                text-align: center;
                                font-size: 18px;
                                font-weight: bold;
                                padding-bottom: 6px;
                            }

                            .clinic_address {
                                text-align: center;
                                font-size: 16px;
                                margin-top: 6px;
                            }

                            .spl {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .spl_flex {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    gap: 12px;
                                }
                            }
                        }

                        // .social_icons {
                        //     display: flex;
                        //     flex-direction: row;
                        //     align-items: center;
                        //     justify-content: center;
                        //     gap: 16px;

                        //     img {
                        //         border-radius: 50%;
                        //         background: #fff;
                        //         box-shadow: 3px 3px 10px #d9d9d9,
                        //             -3px -3px 10px #ffffff;
                        //         padding: 6px;
                        //         height: 28px;
                        //         width: 28px;
                        //     }
                        // }
                    }



                }
            }
        }
    }
}

.appoinment {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .table {
        overflow: auto;
        background-color: white;


        .appoinment_title {
            margin: 0;
            color: #314c7c;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 12px;
        }

        table {
            tbody {
                tr {
                    transition-property: all;
                    transition-duration: .5s;
                    transition-timing-function: ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: lightgrey;
                    }

                    td {
                        padding: 12px;
                        font-size: 16px;
                        color: #3a68b8;
                        white-space: nowrap;

                        @media screen and (max-width:768px) {
                            padding: 8px 12px;
                            white-space: nowrap;
                            border-bottom: 2px solid #f2f6fc;
                            font-size: 14px;
                        }

                        .usr_ico_n_tex {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            gap: 6px;
                            align-items: center;

                            .ico_wrp {
                                background-color: #f2f6fc;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                @media screen and (max-width:768px) {
                                    width: 30px;
                                    height: 30px;
                                }

                                img {
                                    width: 18px;
                                    height: 18px;

                                    @media screen and (max-width:768px) {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }
                            }

                            .title {
                                font-size: 16px;
                                color: #3a68b8;
                                font-weight: 600;

                                @media screen and (max-width:768px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .status_flex {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            flex-direction: row;
                            gap: 6px;

                            .status {
                                height: 24px;
                                width: 24px;
                                border-radius: 50%;

                                @media screen and (max-width:768px) {
                                    height: 12px;
                                    width: 12px;
                                }
                            }

                            .enabled {
                                background-color: #25be64;
                            }

                            .disabled {
                                background-color: #dadde4;
                            }

                            .title {
                                font-size: 16px;
                                color: #3a68b8;

                                @media screen and (max-width:768px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

       
    }
}