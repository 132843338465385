.small_card_main {
    width: calc(25% - 22px);
    padding: 12px;
    border-radius: 7px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    @media screen and (max-width:768px) {
        width: calc(100% - 22px);
    }
    // @media screen and (max-width:1024px) {
    //     width: calc(50% - 22px);
    // }
    .count {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .arrow_wraper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            height: 32px;
            width: 32px;
        }
    }

    h4 {
        color: gray;
        margin: 0;
    }

    h1 {
        font-weight: 600;
        margin: 0;
        color: #333;
        padding: 12px 0px;
    }

    p {

        margin: 0;
    }

    .image_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 48px;
            width: 48px;
        }
    }
}