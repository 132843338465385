.table_actions_wrap {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 1s;

    .action_left_wrap {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 50%;
        transition: all 1s;
    }

    .action_right_wrap {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.own_table_layout_wrap {

    @media screen and (max-width:768px) {
        overflow-x: auto;
    }

    .own_table_layout {
        width: 100%;
        background-color: white;

        @media screen and (max-width:768px) {
            min-width: 900px;
            overflow-x: auto;
        }
    }
}

.search_bar{
    margin-bottom:12px;
    padding: 12px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap:12px;
    .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root{
        max-width: 300px;
    }
}