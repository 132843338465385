
.sp_grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
     grid-column-gap: 20px;
    grid-row-gap: 20px;
    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
    }
    .specializationChip{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background-color: white;
        border-radius: 5px;
        font-size: 18px;
        .buttonGroup{
            display: flex;
            align-items: center;
            gap:10px;
            button{
                padding: 5px 8px !important;
                border-radius: 5px;
                color:white;
                background-color: cornflowerblue;
                box-shadow: none !important;
                outline: none;
                border: none;
            }
        }
    }

}
