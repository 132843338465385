.top_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    @media screen and  (max-width:768px) {
        flex-direction: column;
        gap: 16px;
    }

    .without_grid {
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: lightgray;
    }

    .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .view_status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            position: relative;

            .animate {
                position: absolute;
                top: 0;
                height: 28px;
                width: 28px;
                background-color: white;
                border-radius: 4px;
                transform: scale(1.2);
                transition: all .5s;
            }

            img {
                width: 28px;
                height: 28px;
                cursor: pointer;
                z-index: 9;
            }
        }

        h1 {
            color: #375280;
            padding: 0;
            margin: 0;
        }
    }

    .right {
        button {
            background-color: #8999b4;
            padding: 12px 24px;
            font-size: 18px;
            border: none;
            border-radius: 6px;
            color: white;
            cursor: pointer;
            transition: all .15s;
        }
    }
}

.small_card_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media screen and (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .payment_smalll_card {
        box-shadow: 3px 3px 10px #d9d9d9,
            -3px -3px 10px #ffffff;
        padding: 16px;
        width: calc(100% - 32px);
        border-radius: 6px;
        display: flex;
        height: 132px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
            h2{
                margin: 0;
                color:white;
                font-size:36px;
            }
            p{
                margin: 0;
                color:white;
                font-size: 18px;
                @media screen and (max-width:426px) {
                   font-size:15px
                }
            }
        @media screen and (max-width:426px) {
            height: 80px;
        }
    }
}

.payment_activity {
    width: calc(100% - 32px);
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 3px 3px 10px #d9d9d9,
        -3px -3px 10px #ffffff;
    margin-bottom: 16px;

    p {
        width: 100%;
        text-align: left;
        color: gray;
        margin: 0;
    }

    .chart {
        width: 100%;
        margin-top: 12px;
        height: auto;
    }
}
.appointment_home_main{
    .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 22px;
        grid-row-gap: 22px;

        @media screen and  (max-width:800px) {
            grid-template-columns: 1fr;
        }

        .top_left {
            p {
                margin: 0;
                font-weight: bold;
                color: #314c7c;
                padding-bottom: 16px;
                @media screen and  (max-width:768px) {
                    font-size: 14px;
                }
            }
        }
    }
}