.appoin_wrap {
    margin: 0 12px;

    .appoin_progress {
        background-color: white;
        border-radius: 6px;
        padding: 24px;

        .title_label {
            font-size: 14px;
            font-weight: bold;
        }

        .content_label {
            font-size: 12px;
        }

        @media screen and (max-width:768px) {
            .title_label {
                font-size: 14px;
                font-weight: bold;
            }

            .content_label {
                font-size: 12px;
            }

            .MuiStepLabel-root {
                padding: 0;
            }

            .MuiStepConnector-root {
                margin-left: 24px;
            }
        }
    }
    

    .details_wrap {
        margin-top: 12px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 12px;
        @media screen and (max-width:768px){
            flex-direction: column;
        }

        .customer_wrap {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: calc(100% - 12px);
            margin-bottom: 12px;
            @media screen and (max-width:768px) {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .MuiStepConnector-line {
        height: 90px;
        width: 4px;
        margin-left: 11px;
    }
    .title_label {
        font-size: 14px;
        font-weight: bold;
    }

    .content_label {
        font-size: 12px;
    }

    .MuiStepLabel-root {
        padding: 0;
    }
}

.title_label {
    font-size: 14px;
    font-weight: bold;
}

.content_label {
    font-size: 12px;
}