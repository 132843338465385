.main{
    height:calc(100vh - 40px);
    width:calc(100% - 40px);
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 20px;
    .header{
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: flex-start;
        width:100%;
        height: 10%;
        position: fixed;
        top:0;
        left: 20px;
        h1{
            color:blue;
            margin: 0;
            cursor: pointer;
        }
        span{
            color: red;
            cursor: pointer;
        }
    }
    .body{
        display: flex;
        width: calc(100% - 30px);
        height: calc(80% - 30px);
        justify-content: center;
        align-items: center;
        position: fixed;
        top:10%;
        left:0;
        overflow: auto;
        padding: 15px;
        .body_wrap{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            img{
                @media screen and (max-width:600px) {
                    height: 150px;
                    width: 250px;
                }
            }
            h1{
                color:#2dd4bf;
            }
        }
        .card_content{
                     width: 70%;
                     margin: 0;
                     height: 100%;
                     color:#9ca3af;
                     .logo_container{
                         width: 100%;
                         display: flex;
                         flex-direction: column;
                         align-items: center;
                         img{
                             height:100px;
                             width:150px;
                         }
                         h1{
                             color:#2dd4bf;
                         }
                     }
                     .t_c{
                         .t_c_header{
                             p{
                                 color:#a3a3a3;
                                 font-size: 20px;
                                 font-weight: 600;
                             }
                         }
                         .t_c_content{
                             .list{
                                 font-size: 18px;
                                 font-weight: 600;
                                 color:#a3a3a3;
                             }
                         }
                         .t_c_main_content{
                             width: 100%;
                             text-align: justify;
                             font-size: 18px;
                             .sub_list{
                                 font-weight:600;
                                 font-size: 16px;
                             }
                         }
                     }
                     @media screen and (max-width:1000px) {
                         width: 100%;
                     }
        
                 }
    }
    .footer{
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        position:fixed;
        bottom:0;
        left: 0;
        
        ul{
            display: flex;
            list-style-type:none;
            gap:20px;
            align-items: center;
            justify-content: center;
            padding: 0;
            li{
                color:#38bdf8;
                cursor: pointer;
            }
        }
    }
    

}