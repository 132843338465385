.content_wrap {
    width: calc(100% - 32px);
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .stepper{
        width: 100%;
    }    
}
.bottom_card_wrapper {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 22px;
    margin-top: 22px;

    @media screen and (max-width:556px) {
        grid-template-columns: repeat(1,1fr);
    }
        .profile_card_as_link {
            background-color: white;
            cursor: pointer;
            border-radius: 12px;
            padding: 12px;
            &:hover {
                box-shadow: 3px 3px 10px #d9d9d9,
                    -3px -3px 10px #ffffff;
            }

            .card {
                position: relative;
                transition: all .25s;
                width: 100%;

                .menu {
                    position: absolute;
                    right: 6px;
                    top: 12px;
                    display: none;

                    img {
                        height: 28px;
                        width: 28px;
                        cursor: pointer;
                    }
                }

                .profile_pic {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    padding: 16px 0;

                    img {
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 3px 3px 10px #d9d9d9,
                            -3px -3px 10px #ffffff;
                        height: 84px;
                        width: 84px;
                        padding: 4px;
                    }
                }

                .gridview_card_content {
                    .content_item {
                        margin-bottom: 16px;
                        display: flex;
                    flex-direction: column;
                    align-items: center;

                        .patient_details {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            p {
                                margin: 0;
                            }
                        }

                        .title {
                            color: #314c7c;
                            text-align: center;
                            font-size: 26px;
                            font-weight: bold;
                            padding-bottom: 6px;
                        }

                        .clinic_name {
                            color: #00842c;
                            text-align: center;
                            font-size: 18px;
                            font-weight: bold;
                            padding-bottom: 6px;
                        }

                        .clinic_address {
                            text-align: center;
                            font-size: 16px;
                            margin-top: 6px;
                        }

                        .spl {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .spl_flex {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 12px;
                            }
                        }
                    }

                    .social_icons {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        img {
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 3px 3px 10px #d9d9d9,
                                -3px -3px 10px #ffffff;
                            padding: 6px;
                            height: 28px;
                            width: 28px;
                        }
                    }
                }


                .message {
                    text-align: center;
                    margin: 24px auto 12px auto;
                    width: 40%;
                    font-size: 20px;
                    padding: 6px;
                    border-radius: 6px;
                    transition: all .5s;
                    color: #28257a;

                    &:hover {
                        box-shadow: 3px 3px 10px #d9d9d9,
                            -3px -3px 10px #ffffff;
                    }
                }
            }
        }
    
}