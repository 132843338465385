.ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }
    
    outline: none;
  
    ul,
    ol {
      padding: 0 1rem;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }
  
    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }
  
    pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
  
      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
    hr {
      border: none;
      border-top: 2px solid rgba(#0D0D0D, 0.1);
      margin: 2rem 0;
    }
  }
  .menubar{
    display:flex;
    gap:2px;
    width: 100%;
     flex-wrap: wrap;
    align-items: baseline;
    .menubar_first_child
    {
      display: flex;
      
      gap:3px;
      border-left: 2px solid #17BA86;
      padding: 0px 5px;
      button{
        width:25px;
        height:25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        color: #17BA86;
        border-radius: 6px;
        img{
          height:20px;
          width:20px;
        }
        &:hover{
          background-color: rgba(1, 237, 201, 0.2);
          cursor: pointer;
        }
        
      }
      input{
    appearance: none;
    width: 25px;
    height:25px;
    padding: 0;
    border: none;
    background: none;
    height: 25px;
    color: transparent;
    box-sizing: unset;
    border-radius: 6px;
    opacity: 0;
    bottom: 0;
    left: 0;
    cursor:pointer;
      }
      .is-active{
        background-color: rgba(1, 237, 201, 0.3)
      }
    }
  }
  .menubar_main{
    
    z-index: 99;
    padding: 16px;
    border-radius: 6px;
    background-color: white;
   
  }
  .tiptap_content{
    height: 400px;
    border-radius: 6px;
    padding: 0px 16px;
    width:calc(100% - 32px) !important;
    .content{
      height:calc(100% - 4px) !important;
      width:calc(100% - 24px)  !important;
      border-radius: 6px;
      border:2px solid #17BA86;
      padding:0px 12px;
      margin: 0 !important;
      position:sticky;
      overflow-y: auto;
      box-shadow: none !important;
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: red; 
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
      }
    }
  }
  .tiptap_modal_footer{
    display:flex;
    gap:20px;
    width:calc (100% - 96) !important;
    background-color: white;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width:600px) {
      width:calc (100% - 96) !important;
    }
  }
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    height:25px !important;
    padding:0px 12px !important;
    display: flex;
    font-size: 15px !important;
    border: none !important;
    color:#17BA86 !important ;
    justify-content: space-between;
    margin-right: 5px !important;
    align-items: center;
    outline:none !important;
    &:hover{
      outline:none !important;
    }
  }
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
    color:#17BA86 !important ;
  }
  .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
    color:#17BA86 !important ;
  }
  .css-1fu2e3p-MuiPaper-root-MuiDialog-paper{
    width:100%;
}