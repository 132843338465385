.header {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
            padding: 8px 16px;
            outline: none;
            border: none;
            display: flex;
            background-color: white;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;
            border-radius: 4px;
            font-size: 18px;
            cursor: pointer;
            color: #898888;
    }

    .personal_details {
        padding: 8px 16px;
        outline: none;
        border: none;
        display: flex;
        background-color: #00b3fe;
        box-shadow: none;
        border-radius: 4px;
        font-size: 18px;
        cursor: pointer;
        color: white;

        &:hover {
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;
        }
    }
}

.details_main_wrapper {
    display: grid;
    grid-template-columns: 1.5fr 1.2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 22px;
    margin-bottom: 22px;

    @media screen and (max-width:1024px) {
        grid-template-columns: 1fr;
    }
    .clinic_details_button_grid{
        display: grid;
        grid-template-columns: repeat(1fr);
        grid-template-rows: 4;
        column-gap: 12px;
        row-gap: 12px;
        
        button{
            height: 100%;
        }
        @media screen and (max-width:1024px) {
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: 2;
        }
        @media screen and (max-width:426px) {
            grid-template-columns: repeat(1,1fr) !important;
            
        }
    }
    .profile_card_as_link {
        background-color: white;
        cursor: pointer;
        border-radius: 12px;
        padding: 12px;

        &:hover {
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;
        }

        .card {
            position: relative;
            transition: all .25s;
            width: 100%;

            .menu {
                position: absolute;
                right: 6px;
                top: 12px;
                display: none;

                img {
                    height: 28px;
                    width: 28px;
                    cursor: pointer;
                }
            }

            .profile_pic {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 16px 0;

                img {
                    border-radius: 50%;
                    background: #fff;
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                    height: 84px;
                    width: 84px;
                    padding: 4px;
                }
            }

            .gridview_card_content {
                .content_item {
                    margin-bottom: 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .patient_details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                            margin: 0;
                        }
                    }

                    .title {
                        color: #314c7c;
                        text-align: center;
                        font-size: 26px;
                        font-weight: bold;
                        padding-bottom: 6px;
                    }

                    .clinic_name {
                        color: #00842c;
                        text-align: center;
                        font-size: 18px;
                        font-weight: bold;
                        padding-bottom: 6px;
                    }

                    .clinic_address {
                        text-align: center;
                        font-size: 16px;
                        margin-top: 6px;
                    }

                    .spl {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .spl_flex {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 12px;
                        }
                    }
                }

                .social_icons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;

                    img {
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 3px 3px 10px #d9d9d9,
                            -3px -3px 10px #ffffff;
                        padding: 6px;
                        height: 28px;
                        width: 28px;
                    }
                }
            }


            .message {
                text-align: center;
                margin: 24px auto 12px auto;
                width: 40%;
                font-size: 20px;
                padding: 6px;
                border-radius: 6px;
                transition: all .5s;
                color: #28257a;

                &:hover {
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                }
            }
        }
    }

    .card_shadow {
        background-color: white;
        // box-shadow: 3px 3px 10px #d9d9d9,
        //     -3px -3px 10px #ffffff;
        padding: 16px;
        border-radius: 6px;
       @media screen and (max-width:1024px) {
        height: auto !important;
       }
    }

    .main_middle_wrapper {
        width: 40%;
        height: 300px;
        padding: 16px;
        background-color: white;
        display: flex;
        border-radius: 6px;
        justify-content: center;
        justify-content: space-between;
        box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;

        @media screen and (max-width:963px) {
            width: calc(100% - 32px);
            justify-content: center;
        }

        @media screen and (max-width:426px) {
            width: calc(100% - 32px);
            justify-content: center;
            height: 150px;
        }
    }

    .main_right_wrapper {
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width:963px) {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            margin-top: -24px;
        }

        button {

            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            padding: 12px 0px;

        }
    }
}

.grid_two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 22px;
    .card_shadow_out_padd {
        background-color: white;
        border-radius: 6px;
    }
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
    }

    .appoinment {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        border-radius: 6px;
        .table {
            overflow: auto;
            background-color: white;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;

            .appoinment_title {
                margin: 0;
                color: #314c7c;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            table {
                tbody {
                    tr {
                        transition-property: all;
                        transition-duration: .5s;
                        transition-timing-function: ease-in-out;

                        &:hover {
                            cursor: pointer;
                            background-color: lightgrey;
                        }

                        td {
                            padding: 12px;
                            font-size: 16px;
                            color: #3a68b8;
                            white-space: nowrap;

                            @media screen and (max-width:768px) {
                                padding: 8px 12px;
                                white-space: nowrap;
                                border-bottom: 2px solid #f2f6fc;
                                font-size: 14px;
                            }

                            .usr_ico_n_tex {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                gap: 6px;
                                align-items: center;

                                .ico_wrp {
                                    background-color: #f2f6fc;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    @media screen and (max-width:768px) {
                                        width: 30px;
                                        height: 30px;
                                    }

                                    img {
                                        width: 18px;
                                        height: 18px;

                                        @media screen and (max-width:768px) {
                                            height: 12px;
                                            width: 12px;
                                        }
                                    }
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;
                                    font-weight: 600;

                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .status_flex {
                                display: flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: row;
                                gap: 6px;

                                .status {
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 50%;

                                    @media screen and (max-width:768px) {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }

                                .enabled {
                                    background-color: #25be64;
                                }

                                .disabled {
                                    background-color: #dadde4;
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;

                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

          
        }
    }


}

.main_table {
    .appoinment {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        border-radius: 6px;
        .table {
            overflow: auto;
            background-color: white;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;

            .appoinment_title {
                margin: 0;
                color: #314c7c;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            table {
                

                tbody {
                    tr {
                        transition-property: all;
                        transition-duration: .5s;
                        transition-timing-function: ease-in-out;

                        &:hover {
                            cursor: pointer;
                            background-color: lightgrey;
                        }

                        td {
                            padding: 12px;
                            font-size: 16px;
                            color: #3a68b8;
                            white-space: nowrap;

                            @media screen and (max-width:768px) {
                                padding: 8px 12px;
                                white-space: nowrap;
                                border-bottom: 2px solid #f2f6fc;
                                font-size: 14px;
                            }

                            .usr_ico_n_tex {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                gap: 6px;
                                align-items: center;

                                .ico_wrp {
                                    background-color: #f2f6fc;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    @media screen and (max-width:768px) {
                                        width: 30px;
                                        height: 30px;
                                    }

                                    img {
                                        width: 18px;
                                        height: 18px;

                                        @media screen and (max-width:768px) {
                                            height: 12px;
                                            width: 12px;
                                        }
                                    }
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;
                                    font-weight: 600;

                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .status_flex {
                                display: flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: row;
                                gap: 6px;

                                .status {
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 50%;

                                    @media screen and (max-width:768px) {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }

                                .enabled {
                                    background-color: #25be64;
                                }

                                .disabled {
                                    background-color: #dadde4;
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;

                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

           
        }
    }
}

