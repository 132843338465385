.payment_details_wrap {
    .payment_header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 24px;
        height: calc(200px + 24px);
        z-index: 9;

        .loader::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 200px;
            height: 200px;
            border: 5px lightgreen;
            border-style: solid dotted;
            border-radius: 50%;
            animation: left 5s linear infinite;
        }

        .loader img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            border-radius: 50%;
            height: 200px;
            width: 200px;
            background-color: white;
        }

        @keyframes left {
            from {
                transform: rotate(0);
            }

            to {
                transform: rotate(-1turn);
            }
        }

    }

    .payment_body {
        background-color: white;
        border-radius: 6px;
        margin-top: -86px;
        padding: calc(86px + 12px) 12px 12px 12px;
        margin-bottom: 12px;

        .payment_stepper {
            padding: 12px 0;
        }
    }

    .payment_body_details {
        background-color: white;
        border-radius: 6px;
        padding: 12px;
        margin-bottom: 12px;

        h1 {
            text-align: center;
            font-size: 22px;
            margin-bottom: 24px;

            @media screen and (max-width:768px) {
                font-size: 20px;
            }
        }

        .payment_body_details_wrap {
            display: flex;
            flex-direction: row;
            gap: 24px;
            padding: 0 24px;

            @media screen and (max-width:768px) {
                flex-direction: column;
                padding: 0;
                gap: 0;
            }

            .MuiList-root {
                padding: 0;
            }

            .title {
                font-size: 18px;
                text-transform: uppercase;
                color: rgba($color: #000000, $alpha: .75);
                padding-bottom: 4px;
                border-bottom: 2.5px solid rgb(235, 232, 232);
                width: 100%;
                margin: 0;

                @media screen and (max-width:768px) {
                    font-size: 16px;
                }
            }

            .payment_body_details_wrap_left {
                width: calc(100% - 24px);

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                .payment_body_details_wrap_left_inner {
                    padding: 18px 0 0 0;

                    @media screen and (max-width:768px) {
                        padding: 12px 0 0 0;
                    }

                    .flex {
                        display: flex;
                        justify-content: space-between;
                        gap: 18px;
                        flex-direction: row;
                        margin-bottom: 18px;

                        @media screen and (max-width:768px) {
                            flex-direction: column;
                            margin-bottom: 12px;
                            gap: 12px;
                        }
                    }
                }
            }

            .payment_body_details_wrap_right {
                width: calc(35% - 24px);

                @media screen and (max-width:768px) {
                    width: 100%;
                }

                .payment_body_details_wrap_right_inner {
                    padding: 18px 0 0 0;

                    @media screen and (max-width:768px) {
                        padding: 12px 0 0 0;
                    }

                    .flex {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 6px;

                        .left {
                            font-size: 18px;

                            @media screen and (max-width:768px) {
                                font-size: 16px;
                            }
                        }

                        .right {
                            font-size: 18px;
                            color: green;

                            @media screen and (max-width:768px) {
                                font-size: 16px;
                            }
                        }

                        .bold {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}