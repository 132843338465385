.toggle_button_group{
    border: 1px solid #17BA86;
    padding: 2px !important;
    margin: 0;
    gap:5px;
    transition: all 1s ease-in-out;
    button{
        
        box-shadow: none !important;
        margin: 0;
        background-color: transparent;
        border-radius: 6px;
    }
}