.select_main_wrapper{
    display:flex;
    flex-direction: column;
    padding:10px;
    background-color:white;
    border-radius: 6px;
    position: absolute;
    top:68px;
    right:22px;
    z-index: 99999;
    width: 160px;
    box-shadow: 3px 3px 6px #d9d9d9,
                                    -3px -3px 6px #ffffff;
    .link{
        width: calc(100% - 24px);
        text-align:left;
        font-size: 18px;
        border-radius: 6px;
        cursor: pointer;
        padding:6px 12px;
        gap:12px;
        color:#17BA86;
        
        display: flex;
        align-items: center;
        &:hover{
            background: linear-gradient(171deg, rgba(0,179,254,1) 0%, rgba(1,237,201,1) 100%);
            color:white;
            svg{
                stroke:white;
            }
        }
        p{
            margin: 0;
        }

    }
}