.patientsTop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 22px;
    grid-row-gap: 22px;

    @media screen and (max-width:1024px) {
        grid-template-columns: 1fr;
    }
}