.settings_wrap {
    h1 {
        margin: 0;
    }

    .settings_main {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        column-gap: 22px;
        row-gap: 22px;

        .single_item {
            background-color: white;
            padding: 16px;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .left_icon {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;

                svg {
                    height: 36px;
                    width: 36px;
                    color: gray;
                }

                .primary_text {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    .user_title {
                        font-weight: bold;
                        color: #222;
                    }

                    .user_content {
                        color: #333;
                    }
                }
            }

            .right_action {
                button {
                    border-radius: 6px;
                    padding: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #17BA86;
                    box-shadow: none;
                    transition: all .3s;
                    color: white;

                    &:hover {
                        box-shadow: 2px 2px 4px lightgray;
                    }
                }
            }
        }
    }

    .settings_footer {
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: center;
        margin-top: 22px;

        .b_psw {
            background-color: #00b3fe;
            color: white;
        }
    }
}

.dialog_footer {
    padding: 0 24px 20px 24px;
    display: flex;
    justify-content: flex-end;

    .dialog_footer_child_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        button {
            outline: none;
            border: none;
            background-color: transparent;
            font-size: 15px;
            cursor: pointer;
            padding: 8px 16px;
            border-radius: 3px;
        }

        .b_d_f_update {
            background-color: #17BA86;
            color: white;
            border: 1px solid #17BA86;
        }

        .b_d_f_close {
            border: 1px solid #17BA86;
            color: #17BA86;
        }
    }
}
