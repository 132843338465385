body {
    margin: 0;
    padding: 0;
    background-color: #f2f6fc;
    @media screen and (max-width:2560px) {
        zoom:100%;
    }
    @media screen and (max-width:1456px) {
        zoom:100%;
    }
    @media screen and (max-width:768px) {
        zoom:100%;
    }
    
}

* {
    font-family: "Roboto";
}

a {
    text-decoration: none;
    color: gray;
}

.justify_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .status {
        width: 75%;
        text-align: center;
        padding: 4px 0;
        border-radius: 24px;

        @media screen and (max-width:768px) {
            width: 90%;
        }
    }

    .completed {
        background-color: rgba(green, .75);
        color: white;
    }

    .pending {
        background-color: rgba(#65e765, .5);
        color: white;
    }

    .cancelled {
        background-color: rgba(red, .75);
        color: white;
    }
}

.table_title_cell_link {
    font-weight: 600;
    color: black;
}