// .tbl_main {
//         @media screen and (max-width:426px) {
//             width: 100%;
//             padding:4px 4px;
//             overflow-x: scroll;
//             border-radius: 6px;
//         }
//         thead {
//             width: 100%;

//             th {
//                 padding: 12px;
//                 background-color: transparent;
//                 color: white;
//                 font-weight: 300;
//                 background-color: #243A73;
//                 box-shadow: 3px 3px 2px gray;
//                 &:first-child{
//                     border-top-left-radius: 6px;
//                 }
//                 &:last-child{
//                     border-top-right-radius: 6px;
//                 }
//                 @media screen and (max-width:426px) {
//                     font-size: 14px;
//                     padding:6px 4px;
//                 }
//             }
//         }

//         tbody {
//             tr {
//                 &:last-child{
//                     td{

//                         &:first-child{
//                             border-bottom-left-radius: 6px;
//                         }
//                         &:last-child{
//                             border-bottom-right-radius: 6px;
//                         }
//                     }

//                 }
//                 td {
//                     text-align: center;
//                     padding: 12px 4px;


//                     @media screen and (max-width:426px) {
//                         font-size: 14px;
//                         padding:6px 4px;
//                     }

//                 }

//                 &:nth-child(even) {
//                     background-color: #A5C9CA;
//                 }
//             }

//         }
//     }


.tbl_main {
    width: 100%;
    background-color: white;
    padding: 0;
    border: none;
    border-radius: 6px;

    thead {
        position: sticky;
        top:0;
        overflow-x: auto;
        background: rgb(0, 179, 254);
        background: linear-gradient(195deg, rgba(0, 179, 254, 1) 0%, rgba(1, 237, 201, 1) 100%);
        .sm_date_time{
            width: 150px;
            text-align: center;
            @media screen and (max-width:426px) {
                width: 100px;
            }
        }
        .sm_name{
            width: 150px;
            text-align: center;
        }
        .sm_transection{
            width: 200px;
            @media screen and (max-width:426px) {
                width: 150px;
            }
        }
        .sm_doctor{
            width: 200px;
            text-align: center;
        }
        .sm_clinic_address{
            width: 150px;
            @media screen and (max-width:426px) {
                width: 200px;
            }
        }
        .sm_clinic{
            width: 300px;
            text-align: center;
            @media screen and (max-width:426px) {
                width: 158px;
            }
        }
        th {
            padding: 8px;
            color: white;
            font-weight: 500;

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:last-child {
                border-top-right-radius: 6px;
            }
        }
    }

    tbody {
        tr {
            
            td {
                 text-align: center;
                 padding: 8px;
                // display: flex;
                // flex-direction: row;
                // align-items: ;
                // &:first-child {
                //     display: flex;
                //     gap: 10px;
                //     align-items: center;
                //     text-align: center;
                // }   
                p{
                    @media screen and (max-width:426px) {
                        font-size: 12px !important;
                    }
                    
                }
                .common_cell{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap:8px;
                    @media screen and (max-width:426px) {
                        width: 158px;
                    }
                }
                .sm_date_time{
                    @media screen and (max-width:426px) {
                        width: 100px;
                    }
                }
                .profile_image {
                    height: 30px;
                    width: 30px;
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: rgb(216, 216, 216);
                    @media screen and (max-width:426px) {
                        height:20px;
                        width: 20px;
                    }
                    img {
                        height: 25px;
                        width: 25px;
                        @media screen and (max-width:426px) {
                            height:18px;
                            width: 18px;
                        }
                    }
                }
                
                
            }
            &:nth-child(even){
                background-color: rgb(241, 241, 241);
            }
        }
    }
}