.doctor_appoinment {
    .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 22px;
        grid-row-gap: 22px;

        @media screen and (max-width:800px) {
            grid-template-columns: 1fr;
        }
        .top_right {
            .card_shadow {
                @media screen and (max-width: 500px) {
                    padding: 16px 0px;
                }
                @media screen and (max-width: 400px) {
                    padding: 24px 0px;
                }
            }
        }
    }
}