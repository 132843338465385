.login_main_wrap {
    @media screen and (max-width:426px) {
        background-position: right 50% bottom 100%;
    }

    background-position:right 50% bottom 100%;

    .center_wrap {

        .center_left_section {
            .img {
                width: 80%;

                @media screen and (max-width:556px) {
                    height: 100%;
                    width: 80%;
                }
            }
        }

        .center_right_wrap {
            margin-top:-16px ;
            @media screen and (max-width:556px) {
                margin-top: -100px;
            }
        }
    }

}