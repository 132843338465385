.doctor_appoinment {
    .top {
        display: grid;
        grid-template-columns: calc(50% - 11px) calc(50% - 11px);
        column-gap: 22px;
        row-gap: 22px;

        @media screen and (max-width:1024px) {
            grid-template-columns: 1fr;
        }

        .card_shadow {
            background-color: white;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;
            padding: 16px;
            border-radius: 6px;
            @media screen and (max-width:1024px) {
                height: auto !important;
            }
        }
        .appointments_small_cards{
            @media screen and (max-width:1024px) {
                height:100px !important;
            }
            @media screen and (max-width:426px) {
                height:40px !important;
            }
        }

        .top_right {
            display: grid;
            grid-template-columns: calc(50% - 11px) calc(50% - 11px);
            grid-template-rows: calc(50% - 11px) calc(50% - 11px);
            column-gap: 22px;
            row-gap: 22px;

            @media screen and (max-width:320px) {
                grid-template-columns: 1fr;
            }
            

            .black_shade {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media screen and (max-width:556px) {
                    padding: 36px 0;
                }

                h2 {
                    color: rgba(255, 255, 255, 0.85);
                    font-size: 36px;
                    margin: 0;
                    font-weight: bold;

                    @media screen and (max-width:556px) {
                        font-size: 18px;
                    }

                    sup {
                        font-size: 24px;
                        color: rgba(255, 255, 255, 0.85);

                        @media screen and (max-width:556px) {
                            font-size: 16px;
                        }
                    }
                }

                p {
                    margin: 0;
                    color: rgba(255, 255, 255, 0.75);
                    font-size: 16px;

                    @media screen and (max-width:556px) {
                        font-size: 12px;
                    }
                }
            }

            .white_shade {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media screen and (max-width:556px) {
                    padding: 36px 0;
                }

                h2 {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 36px;
                    margin: 0;
                    font-weight: bold;

                    @media screen and (max-width:556px) {
                        font-size: 18px;
                    }

                    sup {
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.85);

                        @media screen and (max-width:556px) {
                            font-size: 16px;
                        }
                    }
                }

                p {
                    margin: 0;
                    color: rgba(0, 0, 0, 0.75);
                    font-size: 16px;

                    @media screen and (max-width:556px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .table_wrap {
        margin-top: 22px;
        position: sticky;
        top: 120px;
        .appoinment {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            margin-top: 22px;
            .table {
                overflow: auto;
                background-color: white;
                box-shadow: 3px 3px 10px #d9d9d9,
                    -3px -3px 10px #ffffff;
                .appoinment_title {
                    margin: 0;
                    color: #314c7c;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 12px;
                }

                table {
                    tbody {
                        tr {
                            transition-property: all;
                            transition-duration: .5s;
                            transition-timing-function: ease-in-out;

                            &:hover {
                                cursor: pointer;
                                background-color: lightgrey;
                            }

                            td {
                                padding: 12px;
                                font-size: 16px;
                                color: #3a68b8;
                                white-space: nowrap;
                                border-bottom: 2px solid #f2f6fc;

                                @media screen and (max-width:768px) {
                                    padding: 8px 12px;
                                    white-space: nowrap;
                                    border-bottom: 2px solid #f2f6fc;
                                    font-size: 14px;
                                }

                                .usr_ico_n_tex {
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    gap: 6px;
                                    align-items: center;

                                    .ico_wrp {
                                        background-color: #f2f6fc;
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        @media screen and (max-width:768px) {
                                            width: 30px;
                                            height: 30px;
                                        }

                                        img {
                                            width: 18px;
                                            height: 18px;

                                            @media screen and (max-width:768px) {
                                                height: 12px;
                                                width: 12px;
                                            }
                                        }
                                    }

                                    .title {
                                        font-size: 16px;
                                        color: #3a68b8;
                                        font-weight: 600;

                                        @media screen and (max-width:768px) {
                                            font-size: 14px;
                                        }
                                    }
                                }

                                .status_flex {
                                    display: flex;
                                    width: 100%;
                                    align-items: center;
                                    flex-direction: row;
                                    gap: 6px;

                                    .status {
                                        height: 24px;
                                        width: 24px;
                                        border-radius: 50%;

                                        @media screen and (max-width:768px) {
                                            height: 12px;
                                            width: 12px;
                                        }
                                    }

                                    .enabled {
                                        background-color: #25be64;
                                    }

                                    .disabled {
                                        background-color: #dadde4;
                                    }

                                    .title {
                                        font-size: 16px;
                                        color: #3a68b8;

                                        @media screen and (max-width:768px) {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}