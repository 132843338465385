.settings_footer {
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: center;
    margin-top: 22px;
    box-shadow: none;
    gap:16px;
    .btn_change {
        background-color: #00b3fe;
        color: white;
        padding:5px 10px;
        font-size: 15px;
        box-shadow: none;
        &:hover{
            background-color: #00b3fe !important;
            box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;
        }
    }
    .btn_delete{
        background-color: red;
        color: white;
        padding:5px 10px;
        font-size: 15px;
        box-shadow: none;
        &:hover{
            background-color: red !important;
            box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;
        }
    }
}