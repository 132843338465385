.doctor_details {
    .header {
        width: 100%;
        margin-bottom: 16px;

        button {
            padding: 8px 16px;
            outline: none;
            border: none;
            display: flex;
            background-color: white;
            border-radius: 6px;
            font-size: 18px;
            cursor: pointer;
            color: #898888;
            text-transform: capitalize;
            transition: all .5s;
            box-shadow: none;

            &:hover {
                box-shadow: 3px 3px 10px #d9d9d9,
                    -3px -3px 10px #ffffff;
            }
        }
    }

    .grid_3 {
        display: grid;
        grid-template-columns: 1.5fr 1.2fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 22px;
        grid-row-gap: 22px;
        margin-bottom: 22px;

        @media screen and (max-width:1024px) {
            grid-template-columns: 1fr;
        }

        .card_shadow {
            padding-top: 20px;
                padding-bottom: 20px;
            .flex {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;

                p {
                    margin: 0;
                    font-weight: bold;
                    color: #314c7c;
                    padding-bottom: 16px;
                }

                .flex_right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        font-size: 16px;
                        color: #314c7c;
                        font-weight: bold;
                    }

                    svg {
                        height: 22px;
                        width: 22px;
                    }
                }
            }
            @media screen and (max-width:1024px) {
                height:auto !important;
            }
        }
        .mci_certificate{
            @media screen and (max-width:1024px) {
                height: 280px !important;
                
            }
           
        }

        .top_right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
          
            .title{
            h1 {
                margin: 0;
                padding: 0;
                color: #314c7c;
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                
            }
        }
        .mciRegistration{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            b{
                font-size: 16px;
                color: rgb(174, 174, 174);
            }
            p{
                margin: 0;
                padding: 0;
                color: lightgray;
                
            }
            .mci_label{
                color: lightgray;
                font-weight: 500;
                width:80%;
            }
        }

            .spl {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .spl_flex {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    column-gap: 16px;
                    row-gap: 6px;
                    flex-wrap: wrap;
                }
            }

            .social_icons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 16px;

                a {
                    img {
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;
                        padding: 6px;
                        height: 28px;
                        width: 28px;
                    }
                }
            }
        }
    }

    .top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 22px;
        grid-row-gap: 22px;

        @media screen and (max-width:800px) {
            grid-template-columns: repeat(1, 1fr)
        }

        .top_left {
            p {
                margin: 0;
                font-weight: bold;
                color: #314c7c;
                padding-bottom: 16px;
                @media screen and (max-width:768px) {
                    font-size: 14px;
                }
            }
        }
    }

    .appoinment {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        margin-top: 22px;

        .table {
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            background-color: white;
            overflow: auto;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;

            .appoinment_title {
                margin: 0;
                color: #314c7c;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            table {
                tbody {
                    tr {
                        transition-property: all;
                        transition-duration: .5s;
                        transition-timing-function: ease-in-out;

                        &:hover {
                            cursor: pointer;
                            background-color: lightgrey;
                        }

                        td {
                            padding: 12px;
                            font-size: 16px;
                            color: #3a68b8;
                            white-space: nowrap;

                            @media screen and (max-width:768px) {
                                padding: 8px 12px;
                                white-space: nowrap;
                                border-bottom: 2px solid #f2f6fc;
                                font-size: 14px;
                            }

                            .usr_ico_n_tex {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                gap: 6px;
                                align-items: center;

                                .ico_wrp {
                                    background-color: #f2f6fc;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    @media screen and (max-width:768px) {
                                        width: 30px;
                                        height: 30px;
                                    }

                                    img {
                                        width: 18px;
                                        height: 18px;
                                        @media screen and (max-width:768px) {
                                            height: 12px;
                                            width: 12px;
                                        }
                                    }
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;
                                    font-weight: 600;
                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .status_flex {
                                display: flex;
                                width: 100%;
                                align-items: center;
                                flex-direction: row;
                                gap: 6px;

                                .status {
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 50%;
                                    @media screen and (max-width:768px) {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }

                                .enabled {
                                    background-color: #25be64;
                                }

                                .disabled {
                                    background-color: #dadde4;
                                }

                                .title {
                                    font-size: 16px;
                                    color: #3a68b8;
                                    @media screen and (max-width:768px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

          
        }
    }

    .details_main_wrapper {
        width: 100%;
        display: flex;
        gap: 16px;
        flex-direction: row;

        @media screen and (max-width:1024px) {
            display: flex;
            gap: 16px;
            width: 100%;
            margin-bottom: 16px;
            flex-direction: column-reverse !important;
        }


        .details_left_wrapper {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .activity_chart {
                width: calc(100% - 24px);
                padding: 12px;
                background-color: white;
                display: flex;
                flex-direction: column;
                gap: 12px;
                height: 450px;
                border-radius: 6px;
                box-shadow: 3px 3px 10px #d9d9d9,
                    -3px -3px 10px #ffffff;

                @media screen and (max-width:1440px) {
                    height: 350px;
                }

                @media screen and (max-width:1024px) {
                    height: 400px;
                }

                @media screen and (max-width:426px) {
                    height: 230px;
                }

                p {
                    color: gray;
                    font-weight: 500;
                    margin: 0;
                }

                .chart {
                    width: 100%;
                    height: 400px;

                    @media screen and (max-width:1024px) {
                        height: 350px;
                    }

                    @media screen and (max-width:426px) {
                        height: 250px;
                    }
                }

            }

            .chamber_wrapper {
                display: flex;
                width: 100%;
                flex-direction: row;
                gap: 16px;
                height: 300px;
                background-color: white;
                border-radius: 6px;
                box-shadow: 3px 3px 10px #d9d9d9,
                    -3px -3px 10px #ffffff;

                .chambers {
                    padding: 8px 0px;
                    width: 100%;

                    p {
                        margin: 0;
                        padding: 8px 12px;
                        color: #17ba86;
                        font-weight: 400;
                        width: 50%;
                    }

                    ul {

                        list-style-type: none;
                        height: 200px;
                        margin: 0;
                        padding: 8px 12px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        overflow-y: auto;
                        margin-top: 12px;

                        li {

                            .list_div {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                gap: 8px;
                                align-items: center;
                                padding: 8px;
                                color: white;
                                background: linear-gradient(171deg, rgba(0, 179, 254, 1) 0%, rgba(23, 186, 134, 1) 100%);
                                border-radius: 6px;

                                p {
                                    width: 50%;
                                    text-align: left;
                                    margin: 0;
                                    padding: 0;
                                    color: white;
                                }

                                span {
                                    width: 50%;
                                    text-align: left;

                                    @media screen and (max-width:425px) {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                .appointment_request {
                    display: flex;
                    height: 300px;
                    flex-direction: column;
                    gap: 10px;
                    background-color: white;
                    width: 100%;
                    border-radius: 6px;
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;

                    .appointments_table {
                        width: calc(100% - 24px);
                        height: 250px;

                        padding: 0px 12px;
                        padding-bottom: 12px;
                        border-radius: 6px;
                        background-color: white;

                        .table {

                            height: 100%;
                            overflow: auto;
                        }
                    }

                    p {
                        margin: 0;
                        font-weight: 600;
                        font-size: 16px;
                        color: #00b3fe;
                        padding: 8px 12px;

                        @media screen and (max-width:426px) {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }


                }


            }

            @media screen and (max-width:1024px) {
                width: 100%;
            }
        }

        .details_right_wrapper {
            width: 40%;
            display: flex;
            border-radius: 6px;
            flex-direction: column;


            .doctor_profile {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: calc(100% - 16px);
                padding: 32px 8px;
                gap: 12px;
                height: 410px;
                background-color: white;
                border-radius: 6px;
                box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;

                @media screen and (max-width:1440px) {
                    height: 310px;
                }

                @media screen and (max-width:1024px) {
                    padding: 0;
                    height: auto;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                }

                @media screen and (max-width:426px) {
                    padding: 0;
                    height: 120px;
                }

                .profile_img {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width:1024px) {
                        width: 30%;
                        height: 100%;
                        background-color: rgb(23, 186, 134);
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    @media screen and (max-width:426px) {
                        width: 35%;

                    }
                    img {
                        height: 145px;
                        width: 145px;
                        border-radius: 50%;
                       
                        background-color: white;
                        padding: 5px;
                        box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff;

                        @media screen and (max-width:1024px) {
                            height: 75%;
                            width: 90%;
                            padding: 5px;
                            background-color: transparent;
                            box-shadow: none;
                        }

                        @media screen and (max-width:426px) {
                            height: 75%;
                            width: 80%;
                            padding: 0;
                            background-color: transparent;
                            box-shadow: none;
                        }

                    }
                }
                .name_social {
                    display: flex;
                    flex-direction: column;

                    @media screen and (max-width:1024px) {
                        width: 70%;
                        padding: 12px;
                    }

                    .social_icons {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 16px;
                        gap: 16px;
                        justify-content: center;

                        @media screen and (max-width:1024px) {
                            margin-bottom: 0px;
                            align-items: flex-start;
                            justify-content: flex-start;
                        }

                        @media screen and (max-width:426px) {
                            margin-top: 6px;
                        }


                        img {
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 3px 3px 10px #d1d2d5,
                                -3px -3px 10px #ffffff;
                            padding: 6px;
                            height: 28px;
                            width: 28px;

                            @media screen and (max-width:426px) {
                                height: 20px;
                                width: 20px;
                                padding: 3px;
                            }
                        }
                    }

                    .doctor_name {
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        h2 {
                            margin: 0;
                            color: #17ba86;
                            text-align: center;

                            @media screen and (max-width:426px) {
                                font-size: 16px;
                            }
                        }

                        p {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            gap: 10px;
                            margin: 0;
                            margin-top: 12px;

                            span {
                                padding: 12px;
                                border-radius: 6px;
                                background-color: #17ba86;
                                color: white;

                                @media screen and (max-width:426px) {
                                    font-size: 12px;
                                    padding: 6px;
                                }

                                @media screen and (max-width:1024px) {
                                    border-radius: 6px;
                                    padding: 4px;
                                    color: white;
                                    background-color: #01edc9;
                                }
                            }

                            @media screen and (max-width:1024px) {
                                margin-top: 8px;
                            }
                        }

                        @media screen and (max-width:1024px) {
                            align-items: flex-start;
                        }
                    }
                }
            }

            .doctor_profile_details {
                display: flex;
                width: calc(100% - 16px);
                padding: 16px 0px;
                flex-direction: row;
                gap: 10px;
                overflow: hidden;
                flex-wrap: wrap;
                height: 300px;

                @media screen and (max-width:426px) {
                    height: 200px;
                }

                .appointments_common {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 48.7%;
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                    padding: 32px 0px;
                    border-radius: 6px;

                    @media screen and (max-width:1024px) {
                        padding: 4px 0px;
                        width: 49%;
                    }

                    @media screen and (max-width:426px) {
                        padding: 14px 0px;
                        width: 48%;

                    }

                    h2 {
                        text-align: center;
                        color: rgb(241, 241, 13);
                        margin: 0;

                        :first-child() {
                            color: #114aad;
                        }

                        @media screen and (max-width:425px) {
                            font-size: 20px;

                        }
                    }

                    p {
                        text-align: center;
                        font-weight: 600;
                        margin: 0;
                        margin-top: 8px;
                        color: black;

                        @media screen and (max-width:425px) {

                            font-size: 15px;
                        }
                    }
                }
            }

            @media screen and (max-width:1024px) {
                width: 100%;
            }

        }

    }

    .details_second_wrapper {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        margin-bottom: 16px;

        .appointment_common {
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: white;
            padding: 12px 0px;
            height: 400px;
            width: calc(100% - 32px);
            border-radius: 6px;
            box-shadow: 3px 3px 10px #d9d9d9,
                -3px -3px 10px #ffffff;

            .appointments_table {
                width: calc(100% - 24px);
                padding: 0px 12px;
                border-radius: 6px;
                height: 350px;
                background-color: white;

                .table {
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                }
            }

            p {
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                color: #17ba86;
                padding: 8px 12px;

            }
        }
    }

    .card_shadow {
        background-color: white;
        box-shadow: 3px 3px 10px #d9d9d9,
            -3px -3px 10px #ffffff;
        padding: 16px;
        border-radius: 6px;
    }
}
.toggle_button_group{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 0;
    margin: 0;
    .toggle_button{
        &:focus{
            background-color:#17BA86;
            color:white
        }
        font-size: 14px;
        padding: 2px 10px;
        box-shadow: none;
        margin: 0;
        background-color: transparent;
    }
}