.button_small {
    text-transform: capitalize !important;
    padding: 2px 16px !important;
    width: 40px !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    transition: all .5s !important;
    &:hover {
        background-color: white !important;
        box-shadow: 3px 3px 10px #d9d9d9, -3px -3px 10px #ffffff !important;
    }
}