.add_wrap {
    padding: 16px;
    background-color: white;
    border-radius: 6px;
    .form_header {
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
        color: #314c7c;

        @media screen and (max-width:768px) {
            font-size: 16px;
        }
    }
   

    .form_wrap {
        .form_body {
            margin-top: 12px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 18px;
            grid-row-gap: 18px;

            @media screen and (max-width:768px) {
                grid-template-columns: 1fr;
            }

            .error_text {
                color: red;
                font-size: 14px;
            }
        }
    }
}

.submit_wrap {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 16px;

    .submit_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        button{
            padding: 2px 16px !important;
            box-shadow: none !important;
        }
        .clear{
            border: 1px solid lightblue !important;
        }
        .submit{
            background-color: #00b3fe !important;
            color:#fff !important;
        }
    }
}

.add_wrap{
    .location_wrap{
       width: 100%;
       height: 600px;
        margin-top: 16px;
    }
    .submit{
        background-color: #003ca5 !important;
        color:#fff !important;
    }
    
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{
    &:hover{
        cursor:pointer
    }
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    padding-right: 4px !important;
    .location_button{
        box-shadow: none;
        &:hover{
            box-shadow: none !important;
        }
    }
}