.wrapper{
    width: 80px;
    height: 80px;
    position: relative;
    .outer{
        height: 60px;
        width: 60px;
        padding: 10px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 6px 6px  10px -1px rgba(0,0,0,0.15),-6px -6px 10px -1px rgba(255,255,255,0.7);
        .inner{
            height: 58px;
            width: 58px;
            border-radius: 50%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow:inset 4px 4px  6px -1px rgba(0,0,0,0.2),inset -4px -4px 6px -1px rgba(255,255,255,0.7);
            .number{
                font-weight: 600;
                color:red;
            }
        }
    }
    svg{
        position:absolute;
        top:0;
        left:0;
        circle{
            fill:none;
            stroke: url("#GradientColor");
            stroke-width: 10px;
    
        }
    }
    
}