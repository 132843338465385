body {
    margin: 0;
}

.content {
    margin-left: 280px;
    width: calc(100% - 280px);
    margin-top: 84px;
    @media screen and (max-width:2560px) {
        margin-left:280px;
    }
    @media screen and (max-width:1456px) {
   
        margin-left: 310px;
        width: calc(100% - 310px);
    }
    @media screen and (max-width:768px) {
        margin-top: 68px;
    }

    .main_content {
        padding: 22px;

        @media screen and (max-width:768px) {
            padding: 12px;
        }

        .small_card_wrapper {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            @media screen and (max-width:768px) {
                flex-direction: column;
            }
        }

        .progress {
            background-color: white;
            height: 200px;
            width: 200px;
            border-radius: 50%;

            .progress-circle {
                display: flex;
                border-radius: 50%;
                width: 200px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                background-color: transparent;
                box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45), 1px 1px 5px rgba(94, 104, 121, 0.3);
                background-image: conic-gradient(rgb(203, 197, 207) 0deg, #6a5d7521 0deg);

                &::after {
                    content: '';
                    position: absolute;
                    height: 200px;
                    width: 200px;
                    background-color: transparent;
                    border-radius: 50%;
                    box-shadow: inset -1px -1px 5px rgba(255, 255, 255, 1), inset 1px 1px 5px rgba(94, 104, 121, 1);
                }

                .progress-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    height: 80%;
                    width: 80%;
                    border-radius: 50%;
                    background-color: #fff;
                    font-size: 26px;
                    color: #d0d0d0;
                }
            }
        }

        .top_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 16px;

            @media screen and (max-width:768px) {
                flex-direction: column;
                gap: 16px;
            }
            .without_grid {
                width: 100%;
                display: flex;
                flex-direction: row;
                background-color: lightgray;
            }
            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                .view_status {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;
                    position: relative;

                    .animate {
                        position: absolute;
                        top: 0;
                        height: 28px;
                        width: 28px;
                        background-color: white;
                        border-radius: 4px;
                        transform: scale(1.2);
                        transition: all .5s;
                    }

                    img {
                        width: 28px;
                        height: 28px;
                        cursor: pointer;
                        z-index: 9;
                    }
                }

                h1 {
                    color: #375280;
                    padding: 0;
                    margin: 0;
                    font-size: 26px;
                }
            }
            .right {
                display: flex ;
                gap:12px;
                button {
                    background-color: #8999b4;
                    padding: 12px 24px;
                    font-size: 18px;
                    border: none;
                    border-radius: 6px;
                    color: white;
                    cursor: pointer;
                    transition: all .15s;
                }
                @media screen and (max-width:768px) {
                    flex-direction: row;
                    gap: 16px;
                    justify-content: flex-end;
                }
            }
        }

        .without_grid {
            display: flex;
            flex-direction: column;
            gap: 22px;
            @media screen and (max-width:426px){
                gap:10px;
            }
            .card_list {
                height: 25%;
                background-color: white;
                border-radius: 12px;
                padding: 12px 30px;
                position: relative;
                transition: all .25s;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                margin-bottom: 8px;
                align-items: center;
                gap: 32px;

                @media screen and (max-width:475px) {
                    padding: 6px 16px;
                    gap: 20px
                }

                &:hover {
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                }

                .profile_pic {
                    padding: 16px 0;

                    img {
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 3px 3px 10px #d9d9d9,
                            -3px -3px 10px #ffffff;
                        height: 100px;
                        width: 100px;
                        padding: 4px;
                    }
                }

                .menu {
                    display: none;
                }

                .listview_card_content {

                    .content_item {
                        
                        .patient_details {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            p {
                                margin: 0;
                                color: rgb(192, 191, 191);
                                font-weight: 500;

                                span {
                                    font-weight: 200;
                                }
                            }

                            @media screen and (max-width:376px) {
                                font-size: 14px;

                            }
                        }

                        .title {
                            color: #314c7c;
                            font-size: 26px;
                            font-weight: bold;
                            padding-bottom: 6px;

                            @media screen and (max-width:376px) {
                                font-size: 18px;

                            }
                        }

                        .clinic_name {
                            color: #00842c;
                            font-size: 18px;
                            font-weight: bold;
                            padding-bottom: 6px;

                            @media screen and (max-width:376px) {
                                font-size: 14px;
                            }
                        }

                        .clinic_address {
                            font-size: 16px;

                            @media screen and (max-width:376px) {
                                font-size: 12px;
                            }
                        }

                        .spl {
                            width: 100%;

                            .spl_flex {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 12px;
                            }
                        }

                    }

                    .social_icons {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 10px;
                        gap: 16px;

                        img {
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 3px 3px 10px #d1d2d5,
                                -3px -3px 10px #ffffff;
                            padding: 6px;
                            height: 28px;
                            width: 28px;
                        }
                    }
                }

                .message {
                    display: none;

                }


            }
        }

        .list_grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 22px;
            grid-row-gap: 22px;
            @media screen and (max-width:1024px){
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width:556px) {
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
            }

            .profile_card_as_link {
                background-color: white;
                cursor: pointer;
                border-radius: 12px;
                padding: 12px;
                &:hover {
                    box-shadow: 3px 3px 10px #d9d9d9,
                        -3px -3px 10px #ffffff;
                }

                .card {
                    position: relative;
                    transition: all .25s;
                    width: 100%;

                    .menu {
                        position: absolute;
                        right: 6px;
                        top: 12px;
                        display: none;

                        img {
                            height: 28px;
                            width: 28px;
                            cursor: pointer;
                        }
                    }

                    .profile_pic {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 16px 0;

                        img {
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 3px 3px 10px #d9d9d9,
                                -3px -3px 10px #ffffff;
                            height: 84px;
                            width: 84px;
                            padding: 4px;
                        }
                    }

                    .gridview_card_content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .content_item {
                            margin-bottom: 16px;
                            display: flex;
                        flex-direction: column;
                        align-items: center;
                        width:70%;

                            .patient_details {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                p {
                                    margin: 0;
                                }
                            }

                            .title {
                                color: #314c7c;
                                text-align: center;
                                font-size: 26px;
                                font-weight: bold;
                                padding-bottom: 6px;
                            }

                            .clinic_name {
                                color: #00842c;
                                text-align: center;
                                font-size: 18px;
                                font-weight: bold;
                                padding-bottom: 6px;
                            }

                            .clinic_address {
                                text-align: center;
                                font-size: 16px;
                                margin-top: 6px;
                            }

                            .spl {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .spl_flex {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    gap: 12px;
                                }
                            }
                        }

                        .social_icons {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            gap: 16px;

                            img {
                                border-radius: 50%;
                                background: #fff;
                                box-shadow: 3px 3px 10px #d9d9d9,
                                    -3px -3px 10px #ffffff;
                                padding: 6px;
                                height: 28px;
                                width: 28px;
                            }
                        }
                    }


                    .message {
                        text-align: center;
                        margin: 24px auto 12px auto;
                        width: 40%;
                        font-size: 20px;
                        padding: 6px;
                        border-radius: 6px;
                        transition: all .5s;
                        color: #28257a;

                        &:hover {
                            box-shadow: 3px 3px 10px #d9d9d9,
                                -3px -3px 10px #ffffff;
                        }
                    }
                }
            }
        }
    }
}

.bar {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 98;
}

.left_sidebar {
    background: white;
    bottom:0;
    position: fixed;
    left: 0;
    top: 0;
    width: 279px;
    z-index: 99;
    border-right: 1px solid lightgray;
    @media screen and (max-width:2560px) {
        width: 280px;
    }
    @media screen and (max-width:1456px) {
        width: 310px;
    }
    @media screen and (max-width:768px) {
        border-right: none;
        width: 280px;
    }

    .comb_brand_n_sidebar {
        display: flex;
        flex-direction: column;

        .brand_main {
            height: 84px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 22px;

            .brand_full_text {
                font-size: 26px;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                gap: 12px;
                font-weight: bold;

                .first {
                    color: #0000ff;
                }

                .last {
                    color: red;
                }
            }
        }

        .sidebar_main {
            margin: 0 10px;
            position: relative;

            .animated_shadow {
                position: absolute;
                // background: #f6f7fa;
                // box-shadow: 1px 1px 2px #b5b6b8,
                //     -1px -1px 2px #ffffff;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                width: 100%;
                height: 50px;
                z-index: 9;
                transition: all .5s;
            }

            .sidebar_item {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
                padding: 8px 12px;
                cursor: pointer;
                margin-bottom: 6px;
                border-radius: 6px;

                .left {
                    z-index: 9999;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .right {
                    font-size: 18px;
                    font-weight: 500;
                    z-index: 9999;

                    text-transform: capitalize;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .left_sidebar {
        display: none;
    }

    .mobile_sidebar {
        display: block;
    }

    .full_overlay {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 98;
    }

    .content {
        margin-left: 0;
        width: 100%;
    }

    .bar_content {
        margin-left: 0px !important;

    }
}

.bar_content {
    margin-left: 280px;

    .header_main {
        padding: 16px 22px;

        @media screen and (max-width:768px) {
            padding: 8px 11px;
        }

        .header_flex {
            height: 52px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            @media screen and (max-width:600px) {
                justify-content: space-between;
            }
            .left {
                display: none;
                @media screen and (max-width:600px) {
                    flex-direction: row;
                align-items: center;
                    display: flex;
                    gap: 16px;
                }
                .left_menu {
                    img {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                }
            }
            .right {
                .right_wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                    @media screen and (max-width:768px) {
                        gap:0px;
                    }
                    .right_user_icon {
                        display: block;
                    }
                    img {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                    
                }
            }
            
        }
       
    }
}

.shadow {
    transition: all .5s;
    box-shadow: 3px 3px 6px #d9d9d9;
}

.chart_flex {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;

    @media screen and (max-width:768px) {
        flex-direction: column;
    }

    .card_shadow {
        background-color: white;
        padding: 16px;
        border-radius: 6px;
        width: calc(50% - 32px);

        @media screen and (max-width:768px) {
            width: calc(100% - 32px);
            height: auto;
        }
    }
}

.apointment_grid {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .appointment_wrapper {
        width: 300px;
        height: 250px;
    }
}

.input_wrapper {
    position: relative;
    width: 100%;

    .input {
        display: flex;

        input {
            width: 100%;
            padding: 12px 12px 12px 48px;
            outline: none;
            border: none;
            border-radius: 6px;
            font-size: 18px;
            transition: all .5s;
            background: #fff;
            box-shadow: 3px 3px 6px transparent,
                -3px -3px 6px transparent;
            font-weight: 500;

            @media screen and (max-width:768px) {
                width: 100%;
            }

            &:hover,
            &:focus {
                background: #fff;
                box-shadow: 3px 3px 6px #d9d9d9,
                    -3px -3px 6px #ffffff;
            }
        }
    }

    .icon {
        position: absolute;
        top: 52%;
        left: 16px;
        transform: translateY(-50%);

        img {
            width: 24px;
            height: 24px;
        }
    }
}
table{
    width: 100%;
    position: relative;
    overflow: auto;
    border-collapse: collapse;

    thead {
        font-size: 16px;
        font-weight: 500;
        position: sticky;
        top: -1px;
        left: 0;
        background: linear-gradient(171deg, rgba(0,179,254,1) 0%, rgba(23,186,134,1) 100%);
        @media screen and (max-width:768px) {
            font-size: 14px;
        }

        td {
            padding: 16px 12px;
            color: white;
            white-space: nowrap;
        }
    }
}
.table{
    &::-webkit-scrollbar {
        width: 16px;
        background: #eaeaea;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius:6px;
        background-color: #17BA86;
    }
}