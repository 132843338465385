.modal_box{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 50%;
    background-color: white;
    outline: none;
    border-radius: 6px;
    @media screen and (max-width:768px) {
        width: 90%;
    }
}