// .login_wrap {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: 1fr;
//     height: 100vh;

//     @media screen and  (max-width:768px) {
//         grid-template-columns: 1fr;
//         grid-template-rows: 1fr;
//     }

//     .right_wrap {
//         height: 100%;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         background-color: white;

//         @media screen and  (max-width:768px) {
//             background-color: white;
//             padding: 36px 0;
//             margin-top: -48px;
//             border-top-left-radius: 24px;
//             border-top-right-radius: 24px;
//         }

//         .form {
//             width: 50%;
//             margin: auto;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;

//             @media screen and  (max-width:768px) {
//                 width: calc(100% - 24px);
//             }

//             .text_wrap {
//                 margin-bottom: 40px;
//                 text-align: center;

//                 h1,
//                 p {
//                     padding: 0;
//                     margin: 0;
//                 }
//             }

//             .sign_button {
//                 padding: 12px 0;
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: flex-end;
//             }

//             .bottom_wrap {
//                 margin-top: 24px;
//                 text-align: center;

//                 @media screen and  (max-width:768px) {
//                     margin-top: 6px;
//                 }
//             }
//         }
//     }
// }

.login_main_wrap{
    height: 100vh;
    width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
  display: flex;
  flex-direction: column;
  .login_top{
    width:calc(100% - 48px);
    padding-left: 48px;
    @media screen and (max-width:426px) {
        padding-left: 28px;
    }
    h1{
        color:rgb(25, 0, 255);
        span{
            color:red;
        }
    }
  }
  .center_wrap{
    height:100%;
    width: 100%;
   display: grid;
   grid-template-columns: repeat(2,1fr);
    border-radius: 6px;
    @media screen and (max-width:768px) {
        grid-template-columns: 1fr;
    }
    .center_left_section{
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:426px) {
            
        }
        img{
            height: 100%;
            width: 100%;
            @media screen and (max-width:1440px) {
                height: 75%;
            }
            @media screen and (max-width:1024px) {
                height: 50%;
            }
            @media screen and (max-width:768px) {
                height: 70%;
            }
            @media screen and (max-width:556px) {
                height: 100%;
                width: 80%;
            }
        }
        @media screen and (max-width:556px) {
            height: 300px !important;
            width: 100%;
        }
    }
    .center_right_wrap
    {
            height:100%;
            width:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width:556px) {
                margin-top: -64px;
            }
            .text_wrap{
                width: 70%;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
                @media screen and (max-width:768px) {
                    width: 80%;
                }
                h1{
                    color:#439599;
                    span{
                        color:#f87a18;
                    }
                }
                .input_wrap{
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                    gap:22px;
                    width: 100%;
                    margin-top: 22px;
                    align-items: center;
                    .css-1laqsz7-MuiInputAdornment-root{
                        padding: 0px 16px 0px 0px;
                    }
                }
                .sign_in_button{
                    width: 80%;
                    margin-top: 22px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
                .forgot_password_button{
                    width: 80%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
    }
  }
}