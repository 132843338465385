.home_wrap {
    .header {
        border-bottom: 1px solid lightgrey;
    }

    .main_wrap {
        display: flex;
        flex-direction: row;
        transition: all .5s;
        padding-top: 12px;
        position: relative;
        background-color: #f2f6fc;

        .left_wrap {
            width: 20%;
            transition: all .5s;
            min-height: 100vh;
            height: auto !important;
            @media screen and (max-width:768px) {
                position: absolute;
                background: white;
                height: 100%;
                z-index: 999;
                overflow: hidden;
                margin-top: -12px;
            }
        }

        .sidebar_mobile {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .w_zero {
            width: 0%;
        }

        .right_wrap {
            width: 80%;
            transition: all .5s;
            background-color: #f2f6fc;
            display: flex;
            flex-direction: column;

            @media screen and (max-width:768px) {
                width: 100%;
            }

            .right_wrap_content {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 12px;
                grid-row-gap: 0px;
                padding: 0 12px;

                @media screen and (max-width:768px) {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(4, 1fr);
                    grid-column-gap: px;
                    grid-row-gap: 12px;
                }
            }

            .table_wrap {
                padding: 0 12px 12px 12px;
            }

            .latest_appoin_wrap {
                padding: 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;

                svg {
                    height: 24px;
                    width: 24px;
                }

                .latest_appoin_text {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .w_full {
            width: 100%;
        }
    }
}

.no_data_found_wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .no_data_found_img_wrap {
        .no_data_found_img {
            max-height: 320px;
        }
    }

    .no_data_found_text_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            text-align: center;
        }

        @media screen and (max-width:768px) {
            flex-direction: column;
            justify-content: center;
            gap: 12px;
        }
    }
}