.doctor_details_wrap {
    border-radius: 6px;

    .profile_picture_outer_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: auto;
        @media screen and (max-width:768px) {
           
        }

        .profile_picture_wrap {
            position: relative;
            img {
                height: auto;
                width: 100%;
                border-radius: 6px;
            }

            .profile_edit_button {
                position: absolute;
                bottom:10%;
                right: 0;
                background-color: lightgray;
                z-index: 9;
                border-radius: 50%;
            }
        }
    }
}
.alert_msg_pos{
    position: absolute;
    bottom:0;
    right:0;
}